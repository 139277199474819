<template>
  <div id="app">
    <div v-if="!loggedIn" class="login-container">
      <center>
      <img class="logo" src="https://asset.qam.ai/qamai-logo.png" alt="QamAI Logo" />
      <h1>Please log in with MetaMask</h1>
      <button @click="connectMetaMask">Connect MetaMask</button></center>
    </div>
    <div v-if="loggedIn" class="chat-container">
      <img class="logo" src="https://asset.qam.ai/qamai-logo.png" alt="QamAI Logo" />
      <div class="messages">
        <div v-for="(msg, index) in messages" :key="index" :class="`message ${msg.sender}`">
          {{ msg.text }}
        </div>
        <div v-if="loading" class="spinner"></div>
      </div>
      <form @submit.prevent="sendMessage" class="input-form">
        <input type="text" v-model="userMessage" placeholder="Type your message..." />
        <button type="submit">Send</button>
      </form>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      userMessage: '',
      messages: [],
      loading: false,
      loggedIn: false,
    };
  },
  methods: {
    async connectMetaMask() {
      if (typeof window.ethereum !== 'undefined') {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          const chainId = await window.ethereum.request({ method: 'eth_chainId' });

          if (accounts.length > 0 && this.isValidChain(chainId)) {
            this.loggedIn = true;
          } else {
            alert('Please switch to Ethereum or Binance Smart Chain network.');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      } else {
        alert('MetaMask is not installed. Please install MetaMask to continue.');
      }
    },
    isValidChain(chainId) {
      // Ethereum Mainnet: 0x1
      // Binance Smart Chain Mainnet: 0x38
      return chainId === '0x1' || chainId === '0x38';
    },
    async sendMessage() {
      if (this.userMessage.trim() === '') return;

      this.messages.push({ sender: 'user', text: this.userMessage });

      this.loading = true;

      try {
        const response = await axios.post('https://back.qam.ai/api/chat', { message: this.userMessage });
        this.messages.push({ sender: 'bot', text: response.data });
      } catch (error) {
        console.error('Error:', error);
        this.messages.push({ sender: 'bot', text: 'An error occurred. Please try again later.' });
      } finally {
        this.loading = false;
      }

      this.userMessage = '';
    },
  },
};
</script>



<style>
#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f3f3f3;
}

.login-button {
  display: block;
  margin: 10px auto;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.chat-container {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.logo {
  display: block;
  max-width: 90%;
  margin: 10px auto;
}

.messages {
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.message {
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 5px;
}

.message.user {
  background-color: #007bff;
  color: #fff;
  align-self: flex-end;
}

.message.bot {
  background-color: #eee;
  color: #333;
}

.input-form {
  display: flex;
  background-color: #f8f8f8;
  padding: 10px;
  border-top: 1px solid #ddd;
}

input {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
}

button {
  border: none;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 5px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
